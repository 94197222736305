.App {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
}

.sidebar {
  width: 25%;
  background-color: #2c3e50;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.slider-container {
  margin-bottom: 20px;
  width: 100%;
}

.slider-container label {
  display: block;
  margin-bottom: 10px;
}

.slider-container input {
  width: 100%;
}

button {
  padding: 10px 20px;
  background-color: #1abc9c;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #16a085;
}

.results {
  width: 75%;
  padding: 20px;
  background-color: #ecf0f1;
  display: flex;
  flex-direction: column;
}

.results h2 {
  margin-bottom: 20px;
}

.simulation-output {
  background-color: #34495e;
  color: white;
  padding: 20px;
  border-radius: 10px;
}
